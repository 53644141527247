import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <div className="alt">
                    <h3>CHCSD 2025</h3>
                    <span>
                        May 2-3, 2025<br />
                        Sioux Falls Convention Center<br />
                        <strong>Keynote Speakers:</strong><br />
                        TBA<br />
                        <strong>Workshop Speakers:</strong><br />
                        TBA<br />
                    </span>
                </div>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto://marketing@chcsd.org">marketing@chcsd.org</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>1201 N West Ave<br />
                        Sioux Falls, SD 57104<br /></span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
