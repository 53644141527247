import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/speakers">Speakers</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/vendors">Vendors</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/activities">Activities</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/services">Services</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/schedule">Schedule</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/registration">Registration</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/sponsors">Sponsorships</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/lodging">Lodging</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/about">About</Link></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="#">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
